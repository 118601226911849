import PostLoadingComponent from "./PostLoading";
import React, {useEffect, useState} from "react";
import axiosInstance from "../aixos";
import PostsComponent from "./Posts";
import {Badge, Tab, Tabs} from "@mui/material";
import Container from "@mui/material/Container";
import {NavLink} from "react-router-dom";
import Button from "@mui/material/Button";
import PostCardComponent from "./PostCard";
import PostCardFooterAdmin from "./PostCardFooterAdmin";

const MyPosts = () => {
	const PostCard = PostCardComponent(PostCardFooterAdmin);
	const Posts = PostsComponent(PostCard);
	const PostLoading = PostLoadingComponent(Posts);
	const [appState, setAppState] = useState({
		draftPosts: [],
		publishedPosts: [],
		likedPosts: [],
		tab: 1,
		loading: false,
	});

	function filterDrafts(post) {
		return post.status === "draft";
	}

	function filterPublished(post) {
		return post.status === "published";
	}

	function getPostsToDisplay() {
		switch (appState.tab) {
			case 0:
				return appState.draftPosts;
			case 1:
				return appState.publishedPosts;
			case 2:
				return appState.likedPosts;
		}
	}

	const handleChange = (event) => {
		// console.log(appState)
		switch (event.target.name) {
			case "drafts":
				setAppState((prevState) => {
					return {...prevState, tab: 0}
				});
				break;
			case "published":
				setAppState((prevState) => {
					return {...prevState, tab: 1}
				});
				break;
			case "liked":
				setAppState((prevState) => {
					return {...prevState, tab: 2}
				});
				break;
		}
	};

	useEffect(() => {
		setAppState((prevState) => {
			return {...prevState, loading: true}
		});
		axiosInstance.get('api/myposts').then((res) => {
			const allPosts = res.data;
			setAppState((prevState) => {
				return {
					...prevState,
					draftPosts: allPosts.filter(filterDrafts),
					publishedPosts: allPosts.filter(filterPublished),
				}
			});
		});
		axiosInstance.get('api/likes').then((res) => {
			const likedPosts = res.data;
			setAppState((prevState) => {
				return {
					...prevState,
					likedPosts: likedPosts,
					loading: false
				}
			});
		});
	}, [setAppState]);

	return (
		<React.Fragment>
			<Container sx={{borderBottom: 1, borderColor: 'divider', pt: 1}}>
				<section className="myposts-tab-bar">
					<Tabs value={appState.tab} onClick={handleChange}>
						<Badge anchorOrigin={{vertical: '', horizontal: ''}}
									 color="primary" badgeContent={appState.draftPosts.length}>
							<Tab sx={{color: "white", fontWeight: "bold"}} name={"drafts"} label="Drafts"/>
						</Badge>
						<Badge anchorOrigin={{vertical: '', horizontal: ''}}
									 color="primary" badgeContent={appState.publishedPosts.length}>
							<Tab sx={{color: "white", fontWeight: "bold"}} name={"published"} label="Published"/>
						</Badge>
						<Badge anchorOrigin={{vertical: '', horizontal: ''}}
									 color="primary" badgeContent={appState.likedPosts.length}>
							<Tab sx={{color: "white", fontWeight: "bold"}} name={"liked"} label="Liked"/>
						</Badge>
					</Tabs>
					<Button sx={{minWidth: "110px", marginLeft: "auto", my: "5px"}} color="success" component={NavLink}
									variant="contained" to="/new">
						New Trip
					</Button>
				</section>
			</Container>
			<PostLoading isLoading={appState.loading} posts={getPostsToDisplay()}/>
		</React.Fragment>
	);
};
export default MyPosts;