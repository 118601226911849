import React, {useEffect, useState} from 'react';
import axiosInstance from '../aixos';
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import Compressor from 'compressorjs';


let theme = createTheme();

export default function UserProfile(props) {
	const id = localStorage.getItem('user_id')
	const [profileData, setProfileData] = useState({});
	const navigate = useNavigate();
	const [profileImg, setProfileImg] = React.useState(null);

	useEffect(() => {
		axiosInstance.get("api/user/profile/" + id)
			.then((res) => {
				setProfileData(res.data);
			}).catch((error) => {
			// console.log(error)
		});
	}, [setProfileData]);

	const handleChange = (e) => {
		setProfileData((prevState) => {
			return {
				...prevState,
				[e.target.name]: e.target.value
			}
		});
	}

	const handleImg = (e) => {
		const file = e.target.files[0];
		new Compressor(file, {
			quality: 0.6,
			// The compression process is asynchronous,
			// which means you have to access the `result` in the `success` hook function.
			success(result) {
				result.name = file.name
				setProfileImg(result)
			},
			error(err) {
				alert(
					JSON.stringify(err.message)
				);
			},
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();

		if (profileData.password !== profileData.confirmPassword) {
			alert("Passwords don't match");
		} else {
			if (profileImg != null) {
				formData.append("avatar", profileImg, profileImg.name)
			}
			formData.append("email", profileData.email)
			formData.append("user_name", profileData.user_name)
			formData.append("name", profileData.name)
			formData.append("about", profileData.about)
			if (profileData.password != null && profileData.password !== "") {
				// alert(formData.password)
				formData.append("password", profileData.password)
			}
			axiosInstance
				.patch(`api/user/profile/${id}`, formData)
				.then((res) => {
					// implement error checking
					window.location.reload()
				})
				.catch((error) => {
					alert(
						JSON.stringify(error.response.data)
					);
				});
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Container component="main" sx={{py: 8, color: "white"}} maxWidth="xs">
					<form noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}
										sx={{marginTop: theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<Avatar sx={{width: '10rem', height: '10rem'}}
												src={profileData.avatar != null ? profileData.avatar : "/avatar-default.jpg"}
												alt={profileData.user_name}
								/>
							</Grid>
							<Grid item xs={12}>
								<label htmlFor="avatar" className={"btn"}>
									Set profile pic:
								</label>
								<span> </span>
								<input
									accept="image/*"
									onChange={handleImg}
									name="avatar"
									id="avatar"
									type="file"
									// style={{display: 'none'}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={profileData.email || ''}
									required
									fullWidth
									label="Email Address"
									name="email"
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={profileData.user_name || ''}
									name="user_name"
									required
									fullWidth
									label="Username"
									onChange={handleChange}
									inputProps={{maxLength: 50}}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={profileData.name || ''}
									name="name"
									fullWidth
									label="Name"
									onChange={handleChange}
									inputProps={{maxLength: 100}}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={profileData.about || ''}
									name="about"
									fullWidth
									label="About"
									onChange={handleChange}
									inputProps={{maxLength: 500}}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="New Password"
									type="password"
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="confirmPassword"
									label="Confirm New Password"
									type="password"
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
						</Grid>
						<Grid sx={{marginTop: theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								sx={{margin: theme.spacing(3, 1, 2, 0),}}
								onClick={handleSubmit}
							>
								Update Profile
							</Button>
						</Grid>
					</form>
				</Container>
			</div>
		</ThemeProvider>
	)
}
