import React, {useEffect, useState} from 'react';
import axiosInstance from '../aixos'

import PostLoadingComponent from "./PostLoading";
import PostsComponent from "./Posts";
import PostCardComponent from "./PostCard";
import PostCardFooter from "./PostCardFooter";


const PostsQuery = () => {
	const PostCard = PostCardComponent(PostCardFooter);
	const Posts = PostsComponent(PostCard);
	const PostLoading = PostLoadingComponent(Posts);
	const [appState, setAppState] = useState({
		posts: null,
		loading: false,
	});

	useEffect(() => {
		setAppState({loading: true});
		axiosInstance.get('api/posts' + window.location.search).then((res) => {
			const allPosts = res.data;
			setAppState({posts: allPosts, loading: false});
		});
	}, [setAppState]);

	return (
		<div className="App">
			<PostLoading isLoading={appState.loading} posts={appState.posts}/>
		</div>
	);
};
export default PostsQuery;
