import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {CardActionArea} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";


function PostCard(CardFooterComponent) {

	return function PostCardComponent(props) {
		const {post} = props;

		function extractContent(html) {
			return new DOMParser()
				.parseFromString(html, "text/html")
				.documentElement.textContent;
		}

		return (
			<Grid item xs={12}>
				<Container sx={{maxWidth: 700}}>
					<CardActionArea component="a" href={`/post/${post.slug}`} sx={{borderRadius: 5, maxWidth: 700, py: 0}}>
						<Card sx={{borderRadius: 5}}>
							<CardContent>
								<div  style={{display: 'flex', alignItems: 'center'}}>
									<Link
										sx={{textDecoration: 'none'}}
										href={'/user/' + post.author}
									>
										<Avatar className="author-profile-image"
														src={post.avatar != null ? post.avatar : "/avatar-default.jpg"}
														alt={post.author_username}/>
									</Link>
									<Link
										sx={{textDecoration: 'none'}}
										href={'/user/' + post.author}
									>
									<span>
									 <Typography variant="body1" color="text.primary" margin={"0 7px 0 7px"}>
												{post.author_username}
									 </Typography>
								 	</span>
									</Link>
									<span>
										<Typography variant="body2" color="text.secondary">
											{
												post.published != null ?
													" · " + new Date(post.published).toLocaleDateString('en-US', {
														year: 'numeric',
														month: 'short',
														day: '2-digit'
													}) : null}
            				</Typography>
									</span>
								</div>
							</CardContent>
							<CardContent sx={{display: 'flex', justifyContent: 'space-between', py: 0}}>
								<div>
									<Typography component="h2" variant="h5">
										{post.title}
									</Typography>
									<Typography component={"h2"} variant="body1" paragraph>
										{post.excerpt ? extractContent(post.excerpt) + " ..." : extractContent(post.content.slice(0, 150)) + " ..."}
									</Typography>
								</div>
								{post.cover_image_url != null ?
									<CardMedia
										component="img"
										// sx={{ width: {xs:60, sm: 160, md:120, lg: 160 }, height: {xs:60, sm: '100%', md:120, lg: '100%' }}}
										sx={{
											minWidth: 120,
											maxWidth: 120,
											minHeight: 120,
											maxHeight: 120,
											display: {sm: 'block'},
											borderRadius: 2,
											backgroundSize: 'cover',
											marginLeft: 2
										}}
										// image={post.image}
										image={post.cover_image_url != null ? post.cover_image_url : "/blog-cover-default.jpg"}
										// alt={post.imageLabel}
									/> : null}
							</CardContent>
							<CardFooterComponent {...props} />
						</Card>
					</CardActionArea>
				</Container>
			</Grid>
		)
	}
}

export default PostCard;
