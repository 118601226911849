import React, {useState} from 'react';
import axiosInstance from '../aixos';
import {useNavigate} from 'react-router-dom';
//MaterialUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {createTheme, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import ImageListSelector from "./ImageListSelector";
import {editorConfiguration, extractImageURIs} from "../util"
import {ThemeProvider} from "@mui/styles";


let theme = createTheme();
theme = createTheme(theme, {
	paper: {
		marginTop: theme.spacing(8),
		paddingLeft: theme.spacing(2)

	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
		fontSize: '1.1rem'
	},
	submit: {
		margin: theme.spacing(3, 1, 2, 0),
	},
});

export default function CreatePost() {

	const navigate = useNavigate();
	const initialFormData = Object.freeze({
		title: '',
		// excerpt: '',
		content: '',
		status: 'draft'
	});

	const [formData, updateFormData] = useState(initialFormData);

	const [coverSelector, setCoverSelector] = React.useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value.trim(),
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.post(`api/create`, {
				title: formData.title.trim(),
				// excerpt: formData.excerpt === '' ? null : formData.excerpt,
				content: formData.content.trim(),
				status: formData.status.trim(),
				cover_image_url: formData.cover_image_url
			})
			.then((res) => {
				navigate('/my-items');
			})
			.catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
	};

	function handleClickImgSelect(event) {
		event.preventDefault();
		setCoverSelector(true);
	}

	function handleCoverImgClose(event) {
		setCoverSelector(false);
	}

	function coverImgClick(image_url) {
		updateFormData((prevState) => {
			return {
				...prevState,
				cover_image_url: image_url
			}
		});
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="md">
				<CssBaseline/>
				<div className={theme.paper}>
					<Typography component="h1" variant="h5">
						Share a new experience!
					</Typography>
					<form className={theme.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="title"
									label="Give a suitable title to your post"
									name="title"
									// autoComplete="title"
									onChange={handleChange}
									inputProps={{maxLength: 70, style: {fontSize: '1.8rem'}}}
									InputLabelProps={{style: {fontSize: '1.1rem'}}} // font size of input label
								/>
							</Grid>
							{/*<Grid item xs={12}>*/}
							{/*<TextField*/}
							{/*	variant="outlined"*/}
							{/*	fullWidth*/}
							{/*	id="excerpt"*/}
							{/*	label="Write an excerpt to attract readers"*/}
							{/*	name="excerpt"*/}
							{/*	autoComplete="excerpt"*/}
							{/*	onChange={handleChange}*/}
							{/*	multiline*/}
							{/*	rows={2}*/}
							{/*	inputProps={{maxLength: 150}}*/}
							{/*/>*/}
							{/*</Grid>*/}
							<Grid item xs={12}>
								<div className="editor">
									{/*<h2>Using CKEditor 5 build in React</h2>*/}
									<InputLabel><i>Here goes your story...</i></InputLabel>
									<CKEditor
										editor={Editor}
										config={editorConfiguration}
										data={formData.content}
										onChange={(event, editor) => {
											const data = editor.getData();
											updateFormData({
												...formData,
												content: data
											});
										}}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<Button
									// type="submit"
									variant="outlined"
									color="primary"
									className={theme.submit}
									href="#"
									onClick={handleClickImgSelect}
								>
									Set Cover Image
								</Button>
								<ImageListSelector
									open={coverSelector}
									onClose={handleCoverImgClose}
									selectedImg={formData.cover_image_url}
									imageUriList={extractImageURIs(String(formData.content))}
									onImageSelect={coverImgClick}
									title={"Select the cover image"}
									description={"Should be one of the images used in the content"}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl sx={{minWidth: "110px"}}>
									<InputLabel variant={"standard"}>Status</InputLabel>
									<Select
										value={formData.status}
										name="status"
										onChange={handleChange}
									>
										<MenuItem value={'draft'}>Draft</MenuItem>
										<MenuItem value={'published'}>Published</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									className={theme.submit}
									onClick={handleSubmit}
								>
									Create Post
								</Button>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</ThemeProvider>

	);
}