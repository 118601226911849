import React, {useState} from 'react';
import axiosInstance from '../aixos'
import {useNavigate} from 'react-router-dom'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";


let theme = createTheme();
theme = createTheme(theme, {
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	palette: {
    primary: {
      main: '#FFFFFF'
    }
  }
});

export default function SignUp() {
	const navigate = useNavigate(); // We are using navigate so that we can make a redirect
	const initialFormData = Object.freeze({
		email: '',
		username: '',
		password: '',
		confirmPassword: ''
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.password !== formData.confirmPassword) {
			alert("Passwords don't match");
		} else {
			axiosInstance
				.post(`api/user/register/`, {
					email: formData.email,
					user_name: formData.username,
					password: formData.password,
				})
				.then((res) => {

					// implement error checking

					navigate('/login');
					console.log(res);
				}).catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
		}
	}


	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline/>
				<div style={{marginTop: theme.spacing(8), display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white'}}>
					<Avatar sx={{margin: theme.spacing(5), width: '10rem', height: '10rem'}}>
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<form style={{
						width: '100%', // Fix IE 11 issue.
						marginTop: theme.spacing(1)
					}}
								noValidate>
						<Grid container spacing={2}>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoFocus
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									name="username"
									variant="outlined"
									required
									fullWidth
									id="username"
									label="Username"
									onChange={handleChange}
									inputProps={{maxLength: 50}}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									id="confirmPassword"
									onChange={handleChange}
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							sx={{margin: theme.spacing(3, 0, 3)}}
							onClick={handleSubmit}
						>
							Sign Up
						</Button>
						<Grid container>
							<Grid item>
								<Link href="/login" variant="body2" color={theme.palette.primary.main}>
									Already have an account? Sign in
								</Link>
							</Grid>
						</Grid>
					</form>
				</div>
				<Box mt={5}>
				</Box>
			</Container>
		</ThemeProvider>
	);


}