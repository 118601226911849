import React from "react";
import {ThemeProvider} from "@mui/styles";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {createTheme} from "@mui/material";

let theme = createTheme();
theme = createTheme(theme, {
	footer: {},
});

function Copyright() {
	return (
		<Typography variant="body2" color="white" align="center">
			{'Copyright © '}
			<Link color="inherit" href="#">
				MyDreamsTravel
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

function Footer() {

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Container maxWidth="md" component="footer" sx={{
					borderTop: `1px solid ${theme.palette.divider}`,
					marginTop: theme.spacing(8),
					paddingTop: theme.spacing(3),
					paddingBottom: theme.spacing(3),
					[theme.breakpoints.up('sm')]: {
						paddingTop: theme.spacing(6),
						paddingBottom: theme.spacing(6),
					}
				}}>
					<Copyright/>
				</Container>
			</ThemeProvider>
		</React.Fragment>
	);
}

export default Footer;
