import MyUploadAdapter from './MyUploadAdapter';

export default function extractImageURIs(htmlString) {
	const images = [];
	const htmlImages = new DOMParser().parseFromString(htmlString, "text/html").images;
	for (let i = 0; i < htmlImages.length; i++) {
		images.push(htmlImages.item(i).getAttribute("src"));
	}
	return images;
}

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		// Configure the URL to the upload script in your back-end here!
		return new MyUploadAdapter(loader);
	};
}

const editorConfiguration = {
	extraPlugins: [MyCustomUploadAdapterPlugin],
	image: {
		toolbar: ['toggleImageCaption',
			'imageStyle:inline',
			'imageStyle:block',
			'imageStyle:side',
			'linkImage']
	}
};

function calendarDiff(d1, d2) {
	let result = ""
	let months = (d2.getFullYear() - d1.getFullYear()) * 12;
	months -= d1.getMonth();
	months += d2.getMonth();
	if (months >= 12) {
		result += months / 12 + " years ";
	}
	if (months === 1) {
		result += months + " month";
	} else {
		result += (months <= 0 ? 0 : months + " months");
	}
	return result;
}

export {
	extractImageURIs, editorConfiguration, calendarDiff
}