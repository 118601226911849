import React, {useEffect} from 'react';
import axiosInstance from '../aixos';
import {useNavigate} from 'react-router-dom';

export default function LogOut() {
	const navigate = useNavigate();

	useEffect(() => {
		const response = axiosInstance.post('api/user/logout/blacklist/', {
			refresh_token: localStorage.getItem('refresh_token'),
		});
		localStorage.clear()
		axiosInstance.defaults.headers['Authorization'] = null;
		navigate('/login', {replace: true})
		window.location.reload();
	});
	return <div>Logout</div>;
}