import React, {useState} from 'react';
import axiosInstance from '../aixos';
import {useNavigate} from 'react-router-dom';
//MaterialUI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    }
  }
});

export default function SignIn(props) {
	const navigate = useNavigate();
	const initialFormData = Object.freeze({
		email: '',
		password: '',
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		axiosInstance
			.post(`api/token/`, {
				email: formData.email,
				password: formData.password,
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				localStorage.setItem('user_id', JSON.parse(atob(res.data.refresh.split('.')[1])).user_id);

				axiosInstance.defaults.headers['Authorization'] =
					'JWT ' + localStorage.getItem('access_token');


				if (window.history.state && window.history.state.idx > 0) {
					navigate(-1);
				} else {
					navigate('/'); // the current entry in the history stack will be replaced with the new one with { replace: true }
				}
			})
			.catch((error) => {
				if (error.response) {
					alert(
						JSON.stringify(error.response.data)
					);
				}
			});
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline/>
				<div
					style={{
						marginTop: theme.spacing(8),
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						color: 'white'
					}}>
					<Avatar sx={{margin: theme.spacing(5), width: '10rem', height: '10rem'}}/>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<form
						style={{
							width: '100%', // Fix IE 11 issue.
							marginTop: theme.spacing(1)
						}}
						noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							onChange={handleChange}
							sx={{input:{color: 'white'},label:{color: 'white'}}}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={handleChange}
							sx={{input:{color: 'white'},label:{color: 'white'}}}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleSubmit}
							sx={{margin: theme.spacing(3, 0, 3)}}
						>
							Sign In
						</Button>
						<Grid container>
							<Grid item>
								<Link href="/register" variant="body2" color={theme.palette.primary.main}>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</ThemeProvider>
	);
}