import React, {useEffect, useState} from 'react';
import axiosInstance from '../aixos';
import Avatar from "@mui/material/Avatar";
import {useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {calendarDiff} from "../util"
import PostLoading from "./PostLoading";
import PostLoadingComponent from "./PostLoading";
import PostCardComponent from "./PostCard";
import PostCardFooter from "./PostCardFooter";
import PostsComponent from "./Posts";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";


let theme = createTheme();
theme = createTheme(theme, {
	avatar: {
		width: 200,
		height: 200
	}
});

export default function UserProfile(props) {
	const {id} = useParams();
	const [profile, setProfile] = useState([]);

	const PostCard = PostCardComponent(PostCardFooter);
	const Posts = PostsComponent(PostCard);
	const PostLoading = PostLoadingComponent(Posts);
	const [appState, setAppState] = useState({
		posts: null,
		loading: false,
	});

	useEffect(() => {
		axiosInstance.get("api/user/profile/" + id)
			.then((res) => {
				setProfile(res.data);
			}).catch((error) => {
			// console.log(error)
		});

		setAppState({loading: true});
		axiosInstance.get('api/posts?user=' + id).then((res) => {
			const allPosts = res.data;
			setAppState({posts: allPosts, loading: false});
		});
	}, [setProfile, setAppState]);

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Container sx={{paddingTop: 8, color:"white"}} maxWidth="xs">
					<div style={{marginTop: theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<Avatar sx={{margin: theme.spacing(5), width: '10rem', height: '10rem'}}
										src={profile.avatar != null ? profile.avatar : "/avatar-default.jpg"}
										alt={profile.user_name}
						/>
						<Typography component="body1" variant="body1">
							{profile.user_name}
						</Typography>
						<Typography component="body1" variant="body1">
							{profile.name ? "Name: " + profile.name : null}
						</Typography>
						<Typography component="body1" variant="body1">
							{profile.about ? "About: " + profile.about : null}
						</Typography>
						<Typography component="body1" variant="body1">
							{"Joined on " + new Date(profile.joining_date).toLocaleDateString('en-US', {
													year: 'numeric',
													month: 'short',
													day: '2-digit'
												})}
						</Typography>
					</div>
				</Container>
				<PostLoading isLoading={appState.loading} posts={appState.posts}/>
			</div>
		</ThemeProvider>
	)
}