import React from "react"
import ReactDOM from "react-dom/client"

import App from "./components/App";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./components/register";
import Login from './components/login';
import Logout from './components/logout';
import Single from './components/single';
import Search from './components/Search';
import PostsQuery from "./components/PostsQuery";
import MyPosts from "./components/MyPosts";
import CreatePost from "./components/CreatePost"
import EditPost from "./components/EditPost"
import UserProfile from "./components/UserProfile"
import EditProfile from "./components/EditProfile"

const routing = (
	<Router>
		<Header/>
		<Routes>
			<Route exact path="/" element={<App/>}/>
			<Route path="/register" element={<Register/>}/>
			<Route path="/login" element={<Login/>}/>
			<Route path="/logout" element={<Logout/>}/>
			<Route exact path="/post/:slug" element={<Single/>}/>
			<Route path="/search" element={<Search/>}/>
			<Route path="/posts" element={<PostsQuery/>}/>
			<Route path="/my-items" element={<MyPosts/>}/>
			<Route path="/new" element={<CreatePost/>}/>
			<Route exact path="/post/:slug/edit" element={<EditPost/>}/>
			<Route path="/user/:id" element={<UserProfile/>}/>
			<Route path="/profile" element={<EditProfile/>}/>
		</Routes>
		<Footer/>
	</Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	routing
);