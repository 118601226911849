import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";

let theme = createTheme();

function Posts(Component) {
	return function PostComponent(props) {
		const {posts} = props;
		if (!posts || posts.length === 0)
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<Typography variant="h5" color="white">
						No posts yet
					</Typography>
				</div>
			)
		return (
			<React.Fragment>
				<ThemeProvider theme={theme}>
					<Container sx={{py: 3}} maxWidth="lg">
						{/*<section className="post-feed">*/}
						<Grid container spacing={3}>
							{posts.map((post) => (
								// The tag below includes the markup for each post - components/common/PostCard.js
								<Component key={post.id} post={post}/>
							))}
						</Grid>
						{/*</section>*/}
					</Container>
				</ThemeProvider>
			</React.Fragment>
		);
	};
}

export default Posts;