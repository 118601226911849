import React, {useEffect, useState} from 'react';
import axiosInstance from '../aixos';
import {useNavigate, useParams} from 'react-router-dom';
//MaterialUI
import {ThemeProvider} from '@mui/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import {createTheme, ToggleButton} from "@mui/material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {Edit, ThumbUp} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {default as SubmitButton} from "@mui/material/Button/Button";
import Comment from "./Comment";

let theme = createTheme();

export default function Post() {
	// useParams allows use to extract parameters from the url, in this case "slug"
	const {slug} = useParams();
	const navigate = useNavigate();
	const [post, setPost] = useState([]);

	useEffect(() => {
		axiosInstance.get("api/post/" + slug)
			.then((res) => {
				const likes = res.data.likes;
				setPost(res.data);
				updateLike({
					count: likes.length,
					liked: likes.find(function (like) {
						return String(like.user) === String(localStorage.getItem('user_id'))
					})
				});
			}).catch((error) => {
			// console.log(error)
		});
	}, [setPost]);

	const [like, updateLike] = useState({
		count: 0,
		liked: false
	})

	function handleLike(event) {
		if (localStorage.getItem('access_token') == null) {
			navigate('/login')
		}
		axiosInstance.post("api/consumable/" + post.id + "/like").then(
			updateLike((prevState) => {
				if (prevState['liked']) {
					return {
						count: parseInt(prevState['count']) - 1,
						liked: false
					}
				} else {
					return {
						count: parseInt(prevState['count']) + 1,
						liked: true
					}
				}
			})
		)
	}

	const [newComment, updateNewComment] = useState("");

	const handleCommentChange = (e) => {
		updateNewComment((prevState) => {
			return e.target.value
		});
	}

	const handleCommentClick = (e) => {
		if (localStorage.getItem('access_token') == null) {
			navigate('/login')
		}
	}

	const handleSubmitComment = (e) => {
		e.preventDefault();
		axiosInstance
			.post("api/comment", {
				text: newComment.trim(),
				parent: post.id
			})
			.then((res) => {
				window.location.reload();
			})
			.catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="md" sx={{marginTop: theme.spacing(4)}}>
				<Box border={1.5} borderColor='grey.500' flexGrow={1} py={4} paddingLeft={4} borderRadius={5}
						 sx={{bgcolor: 'white'}}>
					<Grid container>
						<Grid item xs={11}>

							<div style={{display: 'flex', alignItems: 'center'}}>
								<Link
									sx={{textDecoration: 'none'}}
									href={'/user/' + post.author}
								>
									<Avatar className="author-profile-image"
													src={post.avatar != null ? post.avatar : "/avatar-default.jpg"}
													alt={post.author_username}/>
								</Link>
								<Link
									sx={{textDecoration: 'none'}}
									href={'/user/' + post.author}
								>
								<span>
								 <Typography variant="body1" sx={{padding: '0 7px 0 7px'}}>
											{post.author_username}
								 </Typography>
								</span>
								</Link>
								<span>
									<Typography variant="body2" color="textSecondary">
										{
											post.published != null ?
												" Published on " + new Date(post.published).toLocaleDateString('en-US', {
													year: 'numeric',
													month: 'short',
													day: '2-digit'
												}) : null}
									</Typography>
								</span>
							</div>
							<div>
								<Typography
									component="h1"
									variant="strong"
									align="left"
									color="textPrimary"
									gutterBottom
								>
									{post.title}
								</Typography>
								{/*<Box*/}
								{/*	component="img"*/}
								{/* sx = {{maxWidth: '100%', height: 'auto'}}*/}
								{/* src={post.avatar != null ? post.avatar : "/avatar-default.jpg"}*/}
								{/*/>*/}
								{/*<Typography*/}
								{/*	variant="h5"*/}
								{/*	color="textSecondary"*/}
								{/*	paragraph*/}
								{/*>*/}
								{/*	<p>{post.excerpt}</p>*/}
								{/*</Typography>*/}
								<Typography
									variant="body1"
									color="textBody"
									paragraph
									className={`ck-content`}
								>
									{parse(String(post.content))}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={1}>
							<Box sx={{display: 'flex', justifyContent: "flex-end", position: 'sticky', top: theme.spacing(4)}}>
								<div sx={{display: 'flex'}}>
									<ToggleButton onClick={handleLike} sx={{margin: 1, boxShadow: 3}}
																selected={like['liked']}><ThumbUp/></ToggleButton>
									<Typography align={"center"}>{like['count']}</Typography>
								</div>
							</Box>
							{String(localStorage.getItem('user_id')) === String(post.author) ?
								<Box sx={{display: 'flex', justifyContent: "flex-end", position: 'sticky', top: theme.spacing(15)}}>
									<ToggleButton href={`${post.slug}/edit`} sx={{margin: 1, boxShadow: 3}}><Edit/></ToggleButton>
								</Box> : null}
						</Grid>
					</Grid>
				</Box>
				<Box border={1.5} borderColor='grey.500' flexGrow={1} padding={4} borderRadius={5} sx={{bgcolor: 'white'}}
						 marginTop={4}>
					<Grid container>
						<Grid item xs={12}>
							<Box sx={{pb: 2}}>
								<Typography variant="h5">{"Comments"}</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={newComment}
								variant="outlined"
								fullWidth
								label="Add your thoughts..."
								onChange={handleCommentChange}
								onClick={handleCommentClick}
								multiline
								inputProps={{maxLength: 1000}}
							/>
						</Grid>
						{localStorage.getItem("access_token") != null ?
							<Grid container justifyContent={"flex-end"} xs={12}>
								<SubmitButton
									type="submit"
									variant="contained"
									sx={{margin: theme.spacing(1, 0, 1, 0)}}
									onClick={handleSubmitComment}
								>
									Comment
								</SubmitButton>
							</Grid>
							: null
						}
						{post.comments?.map((comment) => (
							<Comment comment={comment}></Comment>
						))}

					</Grid>
				</Box>
			</Container>
		</ThemeProvider>
	);
}