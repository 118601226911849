import React, {useEffect, useState} from "react";
import PostLoadingComponent from "./PostLoading"
import Typography from "@mui/material/Typography";
import PostsComponent from "./Posts";
import PostCardComponent from "./PostCard";
import PostCardFooter from "./PostCardFooter";
import Container from "@mui/material/Container";
import axiosInstance from "../aixos";
import Box from "@mui/material/Box";

function App() {
	const PostCard = PostCardComponent(PostCardFooter);
	const Posts = PostsComponent(PostCard);
	const PostLoading = PostLoadingComponent(Posts);
	const [appState, setAppState] = useState({
		loading: false,
		posts: null,
	});

	useEffect(() => {
		setAppState({loading: true});
		axiosInstance.get("api/")
			.then((response) => response.data)
			.then((posts) => {
				return setAppState({loading: false, posts: posts});
			}).catch(err => {
			console.log(err)
		})
	}, [setAppState]);

	return (
		<div className="App">
			<div style={{backgroundImage: "url('/home.jpg')",
				position: 'relative',
				width: '100%',
				minHeight: '50vh',
				backgroundSize: 'cover',
				justifyContent: "center",
				display: "flex",
				flexDirection: 'column'
			}}>
				<div style={{ justifyContent: "center", alignItems: "center", display: "flex",
				flexDirection: 'column'}}>
					<div style={{color: 'white', fontSize: "calc(2.5vw + 2.5vh + 1.25vmin)", textAlign: "center", textShadow: '0 0 3px #125c86, 0 0 5px #125c86'}}>My<br/>Dreams<br/>Travel</div>
					<div style={{color: 'white', fontSize: "calc(1vw + 1vh + 0.5vmin)", width: '50%', textAlign: "center", textShadow: '0 0 5px #125c86, 0 0 3px #125c86'}}>Love to Live and Live to Travel! Read stories, get motivated, go on that dream vacation and share your experiences</div>
				</div>
				<div style={{position:'absolute', top: '1px', right:'1px', textDecoration: 'none', color: 'white', fontSize:"calc(.5vw + .5vh + .25vmin)", textAlign: 'right'}}>Photo by <a style={{color:'#ffffff', textDecoration: 'none'}} href="https://unsplash.com/@charissek?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Charisse Kenion</a> on <a style={{color:'#ffffff', textDecoration: 'none'}} href="https://unsplash.com/photos/DhZ3N0QAbpo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></div>
			</div>

			<Container maxWidth="lg">
				<Typography variant="h5" sx={{paddingTop: 2, color: "#ffffff"}}>Latest Experiences</Typography>
			</Container>
			<PostLoading isLoading={appState.loading} posts={appState.posts}/>
		</div>
	)
}

export default App;