import Grid from "@mui/material/Grid";
import * as React from "react";
import {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import axiosInstance from "../aixos";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
	createTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {default as SubmitButton, default as DialogButton} from "@mui/material/Button/Button";
import {ThemeProvider} from "@mui/styles";


let theme = createTheme();


export default function Comment(props) {
	const {comment} = props;
	const navigate = useNavigate();

	const [commentText, updateCommentText] = useState(comment.text);

	const [like, updateLike] = useState({
		count: comment.likes.length,
		liked: false
	})

	function handleLike(event) {
		if (localStorage.getItem('access_token') == null) {
			navigate('/login')
		}
		axiosInstance.post("api/consumable/" + comment.id + "/like").then(
			updateLike((prevState) => {
				if (prevState['liked']) {
					return {
						count: parseInt(prevState['count']) - 1,
						liked: false
					}
				} else {
					return {
						count: parseInt(prevState['count']) + 1,
						liked: true
					}
				}
			})
		)
	}

	const [replyOpen, toggleReplyOpen] = useState(false);

	function toggleReply(event) {
		toggleReplyOpen(!replyOpen);
	}

	const [newReply, updateNewReply] = useState("");

	const handleReplyChange = (e) => {
		updateNewReply((prevState) => {
			return e.target.value
		});
	}

	const handleReplyClick = (e) => {
		if (localStorage.getItem('access_token') == null) {
			navigate('/login')
		}
	}

	const handleSubmitReply = (e) => {
		e.preventDefault();
		axiosInstance
			.post("api/comment", {
				text: newReply.trim(),
				parent: comment.id
			})
			.then((res) => {
				window.location.reload();
			})
			.catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
	};

	const [editOpen, toggleEditOpen] = useState(false);

	function toggleEdit(event) {
		toggleEditOpen(!editOpen);
	}

	const handleCommentChange = (e) => {
		updateCommentText((prevState) => {
			return e.target.value
		});
	}

	const handleSubmitEdit = (e) => {
		e.preventDefault();
		axiosInstance
			.patch("api/comment/" + comment.id, {
				text: commentText.trim(),
			})
			.then((res) => {
				window.location.reload();
			})
			.catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
	};

	const handleCommentDelete = (e) => {
		e.preventDefault();
		toggleDialogOpen(true);
	}

	const [dialogOpen, toggleDialogOpen] = React.useState(false);

	const handleDialogClose = (event) => {
		if (event.target.name === "confirm") {
			axiosInstance.delete("api/comment/" + comment.id).then(r => {
			});
			toggleDialogOpen(false);
			window.location.reload();
		}
		toggleDialogOpen(false);
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid container sx={{paddingTop: 2, paddingBottom: 1}}>
				<Grid item xs={comment.depth - 1}>
				</Grid>
				<Grid item xs={13 - comment.depth}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Link
							sx={{textDecoration: 'none'}}
							href={'/user/' + comment.author}
						>
							<Avatar className="author-profile-image"
											src={comment.avatar != null ? comment.avatar : "/avatar-default.jpg"}
											alt={comment.author_username}/>
						</Link>
						<Link
							sx={{textDecoration: 'none'}}
							href={'/user/' + comment.author}
						>
					<span>
					 <Typography variant="body1" sx={{padding: '0 7px 0 7px'}}>
								{comment.author_username}
					 </Typography>
					</span>
						</Link>
						<span>
						<Typography variant="body2" color="textSecondary">
							{" Commented on " + new Date(comment.created).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'short',
								day: '2-digit'
							})}
						</Typography>
					</span>
					</div>
				</Grid>
				<Grid item xs={comment.depth}>
				</Grid>
				<Grid item xs={12 - comment.depth}>
					{editOpen ?
						<div>
							<TextField
								value={commentText}
								variant="outlined"
								fullWidth
								onChange={handleCommentChange}
								multiline
								inputProps={{maxLength: 1000}}
							/>
							<Grid container>
								<Grid container justifyContent={"flex-end"} item xs={12}>
									<SubmitButton
										type="submit"
										variant="contained"
										sx={{margin: theme.spacing(1, 0, 1, 0)}}
										onClick={handleSubmitEdit}
									>
										Modify
									</SubmitButton>
								</Grid>
							</Grid>
						</div>
						: <Typography variant="body2" className={theme.content}>{comment.text}</Typography>
					}
					<div style={{display: 'flex', alignItems: 'center'}}>
						<IconButton onClick={handleLike} size="small"><ThumbUpIcon></ThumbUpIcon></IconButton>
						<Typography variant="body2" color="textSecondary"
												sx={{fontSize: '0.8rem', padding: '15px 20px 1px 0px'}}>{like['count']} </Typography>
						{comment.depth < 3 ?
							<Link href={"javascript:;"} onClick={toggleReply} sx={{textDecoration: "none"}}>
								<Typography variant="body2" color="textSecondary"
														sx={{fontSize: '0.8rem', padding: '15px 20px 1px 0px'}}>Reply </Typography>
							</Link>
							: null
						}
						{(comment.author) == localStorage.getItem("user_id") ?
							<Link href={"javascript:;"} onClick={toggleEdit} sx={{textDecoration: "none"}}><span>
								<Typography variant="body2" color="textSecondary"
														sx={{fontSize: '0.8rem', padding: '15px 20px 1px 0px'}}>Edit </Typography>
							</span></Link>
							: null
						}
						{(comment.author) == localStorage.getItem("user_id") ?
							<Link href={"javascript:;"} onClick={handleCommentDelete} sx={{textDecoration: "none"}}><span>
								<Typography variant="body2" color="textSecondary"
														sx={{fontSize: '0.8rem', padding: '15px 20px 1px 0px'}}>Delete </Typography>
							</span></Link>
							: null
						}
					</div>
				</Grid>
				{replyOpen ?
					<Grid container>
						<Grid item xs={comment.depth}>
						</Grid>
						<Grid item xs={12 - comment.depth} sx={{paddingTop: 2}}>
							<TextField
								value={newReply}
								variant="outlined"
								fullWidth
								label="Your reply..."
								onClick={handleReplyClick}
								onChange={handleReplyChange}
								multiline
								inputProps={{maxLength: 1000}}
							/>
						</Grid>
						<Grid container justifyContent={"flex-end"} item xs={12}>
							<SubmitButton
								type="submit"
								variant="contained"
								sx={{margin: theme.spacing(1, 0, 1, 0)}}
								onClick={handleSubmitReply}
							>
								Reply
							</SubmitButton>
						</Grid>
					</Grid>
					: null
				}
				{comment.comments?.map((x) => (
					<Comment comment={x}></Comment>
				))}
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
				>
					<DialogTitle>
						{"Confirm Deleting the comment"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Please note that the comment wouldn't be recoverable once removed.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<DialogButton name="cancel" onClick={handleDialogClose}>Cancel</DialogButton>
						<DialogButton name="confirm" onClick={handleDialogClose}>Confirm</DialogButton>
					</DialogActions>
				</Dialog>
			</Grid>
		</ThemeProvider>
	)
}

