import Typography from "@mui/material/Typography";
import * as React from "react";
import {styled} from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import {ThumbUp} from "@mui/icons-material";

export default function PostCardFooter(props) {
	const {post} = props;

	const CardContentNoPadding = styled(CardContent)(`
		padding: 0;
		&:last-child {
			padding-bottom: 5px;
		}
	`);

	return (
		<React.Fragment>
			<CardContentNoPadding sx={{display: 'flex', justifyContent: 'space-between', px: '16px'}}>
				{post.likes != null ?
					<div>
						<Typography variant="body2" color="text.secondary" align={"right"}>
							<ThumbUp fontSize="small"/><span>	</span>{post.likes.length}
						</Typography>
					</div> : null}
				<div>
					<Typography variant="body2" color="text.secondary" align={"right"}>
						{Math.ceil(post.content.length / (250 * 4.7))} min read
					</Typography>
				</div>
			</CardContentNoPadding>
		</React.Fragment>
	)
}