import * as React from 'react';
import {useEffect, useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import {NavLink, useNavigate} from "react-router-dom";
import axiosInstance from "../aixos";
import {createTheme, IconButton, Menu, MenuItem} from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import Avatar from "@mui/material/Avatar";
import {ThemeProvider} from "@mui/styles";

const Search = styled('div')(({theme}) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

let theme = createTheme();


export default function Header() {
	let navigate = useNavigate();
	const [data, setData] = useState('');
	const [loggedIn, setLoggedIn] = useState(false);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [avatarUrl, setAvatarUrl] = useState([]);


	useEffect(() => {
		setLoggedIn(!!localStorage.getItem('access_token'));
		if (loggedIn) {
			axiosInstance.get("api/user/profile/" + localStorage.getItem('user_id'))
				.then((res) => {
					setAvatarUrl(res.data.avatar);
				}).catch((error) => {
				// console.log(error)
			});
		}
	})

	const goSearch = (e) => {
		if (e.key == 'Enter') {
			navigate({
				pathname: '/search',
				search: '?search=' + data,
			});
			window.location.reload();
		}
	};

	const clickSearch = (e) => {
		navigate({
			pathname: '/search',
			search: '?search=' + data,
		});
		window.location.reload();
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{flexGrow: 1}}>
				<AppBar
					position="static"
					elevation={0}
					sx={{borderBottom: `1px solid ${theme.palette.divider}`, bgcolor: "#222425"}}>
					<Toolbar>
						<Typography
							variant="h6"
							noWrap
							component={NavLink}
							sx={{flexGrow: 1, display: {xs: 'flex'}, textDecoration: 'none', color: '#ffffff'}}
							to={"/"}
						>
							MyDreamsTravel
						</Typography>
						<Search
							onKeyDown={goSearch}
							sx={{width: '23vw'}}
						>
							<StyledInputBase
								placeholder="Search…"
								inputProps={{'aria-label': 'search'}}
								value={data}
								onChange={(e) => setData(e.target.value)}
							/>
						</Search>
						<IconButton onClick={clickSearch}><SearchIcon/></IconButton>

						{loggedIn ?
							<Box sx={{flexGrow: 0}}>
								<IconButton onClick={handleOpenUserMenu} sx={{p: 1}}>
									<Avatar alt="Remy Sharp" src={avatarUrl != null ? avatarUrl : "/avatar-default.jpg"}/>
								</IconButton>
								<Menu
									sx={{mt: '45px'}}
									id="menu-appbar"
									// anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									<MenuItem component={NavLink} to={'/profile'}>
										<Typography>Profile</Typography>
									</MenuItem>
									<MenuItem component={NavLink} to={'/my-items'}>
										<Typography>My Items</Typography>
									</MenuItem>
									{loggedIn ?
										<MenuItem component={NavLink} to={'/logout'}>
											<Typography>Log Out</Typography>
										</MenuItem>
										: null
									}
								</Menu>
							</Box> :
							<Button href="#" color="primary" variant="outlined" component={NavLink}
											to="/login">
								Login
							</Button>
						}
					</Toolbar>
				</AppBar>
			</Box>
		</ThemeProvider>
	);
}
