import axios from "axios";

const baseURL = 'https://backend.mydreamstravel.anshulsood.net/';
// const baseURL = 'http://127.0.0.1:8000/';

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('access_token') ? 'JWT ' + localStorage.getItem('access_token') : null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		// console.log(originalRequest)
		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. '
			);
			return Promise.reject(error);
		}

		if (error.response.status === 401) {
			// console.log("in response 401")
			if (originalRequest.url == 'api/token/refresh/') {
				// console.log("orginalRequest url is api/token/refresh/")
				localStorage.clear()
				window.location.href = '/login/';
				return Promise.reject(error);
			}

			if (error.response.data.code === 'token_not_valid' &&
				error.response.statusText === 'Unauthorized') {
				const refreshToken = localStorage.getItem('refresh_token');

				if (refreshToken) {
					const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

					// exp date in token is expressed in seconds, while now() returns milliseconds:
					const now = Math.ceil(Date.now() / 1000);
					// console.log(tokenParts.exp);

					if (tokenParts.exp > now) {
						return axiosInstance
							.post('api/token/refresh/', {refresh: refreshToken})
							.then((response) => {
								localStorage.setItem('access_token', response.data.access);
								localStorage.setItem('refresh_token', response.data.refresh);
								localStorage.setItem('user_id', tokenParts.user_id);

								axiosInstance.defaults.headers['Authorization'] =
									'JWT ' + response.data.access;
								originalRequest.headers['Authorization'] =
									'JWT ' + response.data.access;

								return axiosInstance(originalRequest);
							})
							.catch((err) => {
								// console.log("inside catch");
							});
					} else {
						// console.log('Refresh token is expired', tokenParts.exp, now);
						localStorage.clear()
						window.location.href = '/login/';
					}
				} else {
					// console.log('Refresh token not available.');
					window.location.href = '/login/';
				}
			}
		}

		// console.log("rejecting")
		return Promise.reject(error);
	}
);

export default axiosInstance;