import React, {useEffect, useState} from 'react';
import axiosInstance from '../aixos';
import {useNavigate, useParams} from 'react-router-dom';
//MaterialUI
import {default as DialogButton, default as SubmitButton} from "@mui/material/Button";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {editorConfiguration, extractImageURIs} from "../util"


import {
	Button,
	createTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import ImageListSelector from "./ImageListSelector";
import {ThemeProvider} from "@mui/styles";


let theme = createTheme();

export default function EditPost(props) {
	const {slug} = useParams();

	const navigate = useNavigate();

	const [currData, setCurrData] = useState({title: "", slug: ""});

	const [formData, updateFormData] = useState({
		title: '',
		// excerpt: '',
		content: '',
		status: 'draft'
	});

	const [deleteDialog, setDeleteDialog] = React.useState(false);

	const [coverSelector, setCoverSelector] = React.useState(false);

	useEffect(() => {
		axiosInstance.get("api/post/" + slug).then((res) => {
			updateFormData({
				...formData,
				title: res.data.title.trim(),
				// excerpt: res.data.excerpt,
				status: res.data.status.trim(),
				content: res.data.content.trim(),
				cover_image_url: res.data.cover_image_url
			});
			setCurrData({title: res.data.title, slug: res.data.slug});
		});
	}, [updateFormData, setCurrData]);


	const handleChange = (e) => {
		updateFormData((prevState) => {
			return {
				...prevState,
				// Trimming any whitespace
				[e.target.name]: e.target.value
			}
		});
	}

	const handleUpdate = (e) => {
		e.preventDefault();
		axiosInstance
			.patch("api/post/" + slug, {
				title: formData.title.trim(),
				// excerpt: (formData.excerpt === '' || formData.excerpt == null) ? null : formData.excerpt.trim(),
				content: formData.content.trim(),
				status: formData.status.trim(),
				cover_image_url: formData.cover_image_url
			})
			.then((res) => {
				navigate("/my-items");
				window.location.reload();
			})
			.catch((error) => {
				alert(
					JSON.stringify(error.response.data)
				);
			});
	};

	function handleClickDelete(event) {
		event.preventDefault();
		setDeleteDialog(true);
	}

	const handleDeleteDialogClose = (event) => {
		if (event.target.name === "confirm") {
			axiosInstance
				.delete(`api/post/${currData.slug}`)
				.then((res) => {
					navigate('/my-items');
				})
				.catch((error) => {
					alert(
						JSON.stringify(error.response.data)
					);
				});
			setDeleteDialog(false);
			window.location.reload();
		}
		setDeleteDialog(false);
	};


	function handleClickImgSelect(event) {
		event.preventDefault();
		setCoverSelector(true);
	}

	function handleCoverImgClose(event) {
		setCoverSelector(false);
	}

	function coverImgClick(image_url) {
		updateFormData((prevState) => {
			return {
				...prevState,
				cover_image_url: image_url
			}
		});
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="md">
				<CssBaseline/>
				<div style={{marginTop: theme.spacing(8), paddingLeft: theme.spacing(2), color: 'white'}}>
					<Typography component="h1" variant="h5">
						Share a new experience!
					</Typography>
					<form style={{width: '100%', marginTop: theme.spacing(3)}} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={formData.title}
									variant="outlined"
									required
									fullWidth
									label="Give a suitable title to your post"
									name="title"
									// autoComplete="title"
									onChange={handleChange}
									inputProps={{maxLength: 70, style: {fontSize: '1.8rem'}}}
									InputLabelProps={{style: {fontSize: '1.1rem'}}} // font size of input label
									sx={{input:{color: 'white'},label:{color: 'white'}}}
								/>
							</Grid>
							{/*<Grid item xs={12}>*/}
							{/*	<TextField*/}
							{/*		value={formData.excerpt}*/}
							{/*		variant="outlined"*/}
							{/*		fullWidth*/}
							{/*		label="Write an excerpt to attract readers"*/}
							{/*		name="excerpt"*/}
							{/*		// autoComplete="excerpt"*/}
							{/*		onChange={handleChange}*/}
							{/*		multiline*/}
							{/*		rows={2}*/}
							{/*		inputProps={{maxLength: 150}}*/}
							{/*	/>*/}
							{/*</Grid>*/}
							<Grid item xs={12}>
								<div className="editor">
									{/*<h2>Using CKEditor 5 build in React</h2>*/}
									<InputLabel sx={{color: "white"}}><i>Here goes your story...</i></InputLabel>
									<CKEditor
										editor={Editor}
										config={editorConfiguration}
										data={formData.content}
										onChange={(event, editor) => {
											const data = editor.getData();
											updateFormData((prevState) => {
												return {
													...prevState,
													content: data
												}
											});
										}}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<Button
									// type="submit"
									variant="outlined"
									color="primary"
									sx={{margin: theme.spacing(3, 1, 2, 0), color: "white"}}
									href="#"
									onClick={handleClickImgSelect}
								>
									Set Cover Image
								</Button>
								<ImageListSelector
									open={coverSelector}
									onClose={handleCoverImgClose}
									selectedImg={formData.cover_image_url}
									imageUriList={extractImageURIs(String(formData.content))}
									onImageSelect={coverImgClick}
									title={"Select the cover image"}
									description={"Should be one of the images used in the content"}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl sx={{minWidth: "110px"}}>
									<InputLabel sx={{color: "white"}} variant={"standard"}>Status</InputLabel>
									<Select
										value={formData.status}
										name="status"
										onChange={handleChange}
										sx={{color: "white"}}
									>
										<MenuItem value={'draft'}>Draft</MenuItem>
										<MenuItem value={'published'}>Published</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<SubmitButton
									type="submit"
									variant="contained"
									color="primary"
									sx={{margin: theme.spacing(3, 1, 2, 0)}}
									onClick={handleUpdate}
								>
									Update Post
								</SubmitButton>
								<SubmitButton
									type="submit"
									variant="contained"
									color="secondary"
									sx={{margin: theme.spacing(3, 1, 2, 0)}}
									href="#"
									onClick={handleClickDelete}
								>
									Remove Post
								</SubmitButton>
								<Dialog
									open={deleteDialog}
									onClose={handleDeleteDialogClose}
								>
									<DialogTitle>
										{"Remove the post \"" + currData.title + "\" ?"}
									</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-description">
											Please note that the post wouldn't be recoverable once removed.
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<DialogButton name="cancel" onClick={handleDeleteDialogClose}>Cancel</DialogButton>
										<DialogButton name="confirm" onClick={handleDeleteDialogClose}>Confirm</DialogButton>
									</DialogActions>
								</Dialog>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</ThemeProvider>
	);
}