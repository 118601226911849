import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import Button from "@mui/material/Button";
import * as React from "react";
import {styled} from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import axiosInstance from "../aixos";
import {useNavigate} from "react-router-dom";

export default function PostCardFooterAdmin(props) {
	const {post} = props;
	const navigate = useNavigate();

	const CardContentNoPadding = styled(CardContent)(`
		padding: 0;
		&:last-child {
			padding-bottom: 5px;
		}
	`);

	function handleDelete(event) {
		event.preventDefault();
		setOpen(true);
	}

	const [open, setOpen] = React.useState(false);

	const handleClose = (event) => {
		if (event.target.name === "confirm") {
			axiosInstance
				.delete(`api/post/${post.slug}`)
				.then((res) => {
					navigate('/my-blog');
				})
				.catch((error) => {
					alert(
						JSON.stringify(error.response.data)
					);
				});
			setOpen(false);
			window.location.reload();
		}
		setOpen(false);
	};
	return (
		<React.Fragment>
			<CardContentNoPadding sx={{display: 'flex', justifyContent: 'space-between', px: '16px'}}>
				<div>
				</div>
				{localStorage.getItem('user_id') == post.author ?
					<div>
						<Tooltip title="Edit">
							<IconButton href={`post/${post.slug}/edit`}><Edit/></IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton href="#" color="error" onClick={handleDelete}><Delete/></IconButton>
						</Tooltip>
						<Dialog
							open={open}
							onClose={handleClose}
						>
							<DialogTitle>
								{"Remove the post \"" + post.title + "\" ?"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Please note that the post wouldn't be recoverable once removed.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button name="cancel" onClick={handleClose} autoFocus>Cancel</Button>
								<Button name="confirm" onClick={handleClose}>Confirm</Button>
							</DialogActions>
						</Dialog>
					</div> : null}
			</CardContentNoPadding>
		</React.Fragment>
	)
}