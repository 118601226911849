import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {default as DialogButton} from "@mui/material/Button/Button";


export default function ImageListSelector(props) {
	const [selectedImg, setSelectedImg] = useState(props.selectedImg);

	function handleClose(event) {
		if (event.target.name === "clear") {
			setSelectedImg(undefined);
			props.onImageSelect(null);
		}
		props.onClose()
	}

	function coverImgClick(event) {
		props.onImageSelect(event.target.name);
		setSelectedImg(event.target.name);
	}

	useEffect(() => {
		setSelectedImg(props.selectedImg);
	}, [props.selectedImg])

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
		>
			<DialogTitle>
				{props.title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.description}
				</DialogContentText>
				<ImageList sx={{width: 500, height: 450}} cols={3} rowHeight={164}>
					{props.imageUriList ? props.imageUriList.map((imgUri, index) => (
						<ImageListItem key={index}>
							<img
								src={`${imgUri}`}
								srcSet={`${imgUri}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								loading="lazy"
								name={imgUri}
								style={{border: selectedImg === imgUri ? '5px double red' : 0}}
								onClick={coverImgClick}
							/>
						</ImageListItem>
					)) : null}
				</ImageList>
			</DialogContent>
			<DialogActions>
				<DialogButton name="clear" onClick={handleClose}>Set None</DialogButton>
				<DialogButton name="done" onClick={handleClose}>Done</DialogButton>
			</DialogActions>
		</Dialog>
	);
}